<template>
  <b-container>
    <b-row>
      <b-col cols=6
      class="d-flex flex-column"
      offset=3>
        <h1>Your Settings</h1>
        <b-form
        class="d-flex flex-column align-items-end"
        @submit.stop.prevent="updateUser">
          <component v-for="input in inputs"
          :is="input.component"
          :key="input.id"
          :required="input.required"
          :type="input.type"
          :name="input.type"
          autocomplete="off"
          :disabled="isLoading"
          v-model="input.model"
          :placeholder="input.placeholder" 
          class="mb-3"/>
          <b-button 
          type="submit"
          :disabled="isLoading"
          :class="isLoading ? 'btn-disabled' : 'btn-success'">
            Update Settings
          </b-button>
        </b-form>
        <hr class="w-100 my-4"/>
        <b-button 
        @click="logoutUser"
        class="align-self-start"
        variant="outline-warning"
        :disabled="isLoading">
          Or click here to logout.
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from '@/store'

export default {
  metaInfo () {
    return {
      title: `Settings | test-almocogratis`
    }
  },
  beforeRouteEnter (to, from, next) {
    if (store.getters['user/data']) next()
    else next('/')
  },
  async beforeMount () {
    this.isLoading = true
    await this.updateLastInfoUser()
    this.isLoading = false
    Object.entries(this.getStore('user/data'))
    .forEach(([key, value]) => {
      const index = this.inputs.findIndex(el => el.id === key)
      if (index !== -1) {
        this.$set(this.inputs[index], 'model', value)
      }
    })
  },
  data () {
    return {
      isLoading: false,
      inputs: [
        {
          id: 'image',
          model: undefined,
          component: 'b-form-input',
          type: 'text',
          required: false,
          placeholder: 'URL of profile picture',
        },
        {
          id: 'username',
          model: undefined,
          component: 'b-form-input',
          type: 'text',
          required: true,
          placeholder: 'Username',
        },
        {
          id: 'bio',
          model: undefined,
          component: 'b-form-textarea',
          type: 'area-text',
          required: false,
          placeholder: 'Short bio about you',
        },
        {
          id: 'email',
          model: undefined,
          component: 'b-form-input',
          type: 'email',
          required: true,
          placeholder: 'Email',
        },
        {
          id: 'password',
          model: undefined,
          component: 'b-form-input',
          type: 'password',
          required: false,
          placeholder: 'New password',
        },

      ]
    }
  },
  methods: {
    async updateUser () {
      this.isLoading = true
      const data = this.inputs.map(el => {
        el.model = el.model === '' ? null : el.model
        return [el.id, el.model]
      })
      const body = Object.fromEntries(data)
      await this.$http.updateUser(body).then( ({ data }) => {
        this.setStore('user/data', data.user)
        this.setStore('user/jwtToken', data.user.token)
        this.$router.push(`/@${data.user.username}`)
      }).finally( () => {
        this.isLoading = false
      })
    },
    logoutUser () {
      this.setStore('user/data', undefined)
      this.setStore('user/jwtToken', undefined)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss">
  textarea {
    min-height: 250px;
  }
</style>